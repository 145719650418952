






import LabSim from '@/tasks/components/simulations/LabSim.vue';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import {CompletableSimMixin} from '@/tasks/mixins/completable-sim';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'EquilibriumPartBSIM',
  components: {LabSim, LabTask},
  mixins: [CompletableSimMixin()],
});
